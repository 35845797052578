<template>
<div class="zutool-cpsite">
	<!-- splitterを使わないページ-->
	<component v-if="computedPage.name !== 'WeatherGraph' && computedPage.name !== 'WeatherMap'"
		:is="computedPage.name"
		@containerHeight="setContainerHeight($event)"
		v-bind="computedPage.props"
		:key="computedPage.key"
	/>
	<!-- splitterを使うページ-->
	<v-ons-splitter v-else>
		<common-header
			v-if="computedPage.name === 'WeatherGraph' || computedPage.name === 'WeatherMap'"
			type="hamburger"
			@click="SET_MENU_OPEN(true)"
			:isGpsButton="false"
		>
			<div class="common-header-title__inner"
				v-if="computedPage.name === 'WeatherGraph'"
				@click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=pointRegistration` } })"
			>
				<h1 class="common-header-title__heading">{{ computedTitle[0] }}</h1>
				<span class="common-header-title__subheading">{{ computedTitle[1] }}</span>
			</div>
			<div class="common-header-title__inner" v-else>
				<h1 class="common-header-title__heading">{{ computedTitle[0] }}</h1>
				<span class="common-header-title__subheading">{{ computedTitle[1] }}</span>
			</div>
		</common-header>

		<!-- サイドメニュー表示 -->
		<v-ons-splitter-side
			collapse
			width="240px"
			:open.sync="menuIsOpen"
			class="side-menu"
		>
			<side-menu-sugotoku :selectedPage="computedPage.name" />
		</v-ons-splitter-side>

		<!-- コンテンツ表示 -->
		<v-ons-splitter-content>
			<div class="zutool-container" id="js_container">
				<component
					:is="computedPage.name"
					@containerHeight="setContainerHeight($event)"
					v-bind="computedPage.props"
				/>
				<!-- アプリ紹介ページボタン -->
				<sugotoku-button class="sugotoku-button" :isIosAppDownload="true" v-if="computedPage.name === 'WeatherGraph'" />
			</div>
		</v-ons-splitter-content>
	</v-ons-splitter>
</div>
</template>

<script>

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_MENU_OPEN, SET_USER_DATA } from '../store/modules/common/mutation-types'

// ライブラリ
import JsSHA from 'jssha'
import axios from 'axios'
import cmnConst from '@/assets/js/constant.js'
import moment from 'moment'
moment.locale('ja')

export default {
	name: 'Cpsite',
	components: {
		// CHANGE: 非同期インポートのチャンクグループサイズが大きいとリクエストは減るが読み込みが遅くなるため戻した。また、computed内でDynamicImportすることも試したがそこまで動作・速度が変わらない、且つテストが難しくなるのでviews以外をチャンクグループとし、viewsについてはそれぞれチャンクを分けるようにした。
		SugotokuTop: () => import(/* webpackChunkName: "SugotokuTop" */ '@/views/SugotokuTop'),
		SorryPage: () => import(/* webpackChunkName: "SorryPage" */ '@/views/SorryPage'),
		WeatherGraph: () => import(/* webpackChunkName: "WeatherGraph" */ '@/views/WeatherGraph'),
		WeatherMap: () => import(/* webpackChunkName: "WeatherMap" */ '@/views/WeatherMap'),
		// PainNote: () => import(/* webpackChunkName: "PainNote" */ '@/views/PainNote'),	// NOTE: 現状スゴ得では使用していないが今後使用する可能性があるためコメントアウトで残しておく
		PointRegistration: () => import(/* webpackChunkName: "PointRegistration" */ '@/views/PointRegistration'),
		FrequentlyAskedQuestions: () => import(/* webpackChunkName: "FrequentlyAskedQuestions" */ '@/views/FrequentlyAskedQuestions'),
		// Profile: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile'),	// NOTE: 現状スゴ得では使用していないが今後使用する可能性があるためコメントアウトで残しておく
		PointTutorial: () => import(/* webpackChunkName: "PointTutorial" */ '@/views/PointTutorial'),
		Stamp: () => import(/* webpackChunkName: "Stamp" */ '@/views/Stamp'),
		StampBloodType: () => import(/* webpackChunkName: "StampBloodType" */ '@/views/StampBloodType'),
		AppDownload: () => import(/* webpackChunkName: "AppDownload" */ '@/views/AppDownload'),
		Info: () => import(/* webpackChunkName: "Info" */ '@/views/Info'),
		Special: () => import(/* webpackChunkName: "Special" */ '@/views/Special'),
		Lp: () => import(/* webpackChunkName: "Lp" */ '@/views/Lp'),
		Terms: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms'),
		NonMember: () => import(/* webpackChunkName: "NonMember" */ '@/views/NonMember'),
		Charactor: () => import(/* webpackChunkName: "Charactor" */ '@/views/Charactor'),
		ColumnTop: () => import(/* webpackChunkName: "ColumnTop" */ '@/views/ColumnTop'),
		ColumnDetail: () => import(/* webpackChunkName: "ColumnDetail" */ '@/views/ColumnDetail'),
		DataHandling: () => import(/* webpackChunkName: "DataHandling" */ '@/views/DataHandling'),
		SelfcheckMeteoropathy: () => import(/* webpackChunkName: "SelfcheckMeteoropathy" */ '@/views/SelfcheckMeteoropathy'),
		SelfcheckMeteoropathyRes: () => import(/* webpackChunkName: "SelfcheckMeteoropathyRes" */ '@/views/SelfcheckMeteoropathyRes'),
		Game: () => import(/* webpackChunkName: "Game" */ '@/views/Game'),
		BalancecheckAutonomicNerves: () => import(/* webpackChunkName: "BalancecheckAutonomicNerves" */ '@/views/BalancecheckAutonomicNerves'),
		BalancecheckAutonomicNervesRes: () => import(/* webpackChunkName: "BalancecheckAutonomicNervesRes" */ '@/views/BalancecheckAutonomicNervesRes'),
		SelfcheckTemperatureDifference: () => import(/* webpackChunkName: "SelfcheckTemperatureDifference" */ '@/views/SelfcheckTemperatureDifference'),
		SelfcheckTemperatureDifferenceRes: () => import(/* webpackChunkName: "SelfcheckTemperatureDifferenceRes" */ '@/views/SelfcheckTemperatureDifferenceRes'),
		SixBadHabit: () => import(/* webpackChunkName: "SixBadHabit" */ '@/views/SixBadHabit'),
		Wallpaper: () => import(/* webpackChunkName: "Wallpaper" */ '@/views/Wallpaper'),
		HeadcheTypeContent: () => import(/* webpackChunkName: "HeadcheTypeContent" */ '@/views/HeadcheTypeContent'),
		HeatstrokeQuizProblem: () => import(/* webpackChunkName: "HeatstrokeQuizProblem" */ '@/views/HeatstrokeQuizProblem'),
		HeatstrokeQuizAnswer: () => import(/* webpackChunkName: "HeatstrokeQuizAnswer" */ '@/views/HeatstrokeQuizAnswer'),
		StresscheckDiagnosis: () => import(/* webpackChunkName: "StresscheckDiagnosis" */ '@/views/StresscheckDiagnosis'),
		StresscheckDiagnosisRes: () => import(/* webpackChunkName: "StresscheckDiagnosisRes" */ '@/views/StresscheckDiagnosisRes'),
		QuizSpring: () => import(/* webpackChunkName: "QuizSpring" */ '@/views/QuizSpring'),
		QuizSpringRes: () => import(/* webpackChunkName: "QuizSpringRes" */ '@/views/QuizSpringRes'),
		SendingPersonalData: () => import(/* webpackChunkName: "SendingPersonalData" */ '@/views/SendingPersonalData'),
		// NOTE: Molecules、Organismsはチャンクグループにする
		CommonHeader: () => import(/* webpackChunkName: "GroupCpsiteComponents" */ '@/components/Molecules/CommonHeader'),
		SideMenuSugotoku: () => import(/* webpackChunkName: "GroupCpsiteComponents" */ '@/components/Organisms/SideMenuSugotoku'),
		SugotokuButton: () => import(/* webpackChunkName: "GroupCpsiteComponents" */ '@/components/Organisms/SugotokuButton')
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			isUnsupportedDevice: false
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['menuOpen', 'cityName', 'isAndroid', 'isWebview', 'isMyPage', 'userId', 'userToken', 'userData']),

		menuIsOpen: {
			get () {
				return this.menuOpen
			},
			set (newValue) {
				this.SET_MENU_OPEN(newValue)
			}
		},

		/**
		 * ページタイトル表示
		 */
		computedTitle () {
			const title = new Array(2)
			const date = moment().format('D日(ddd)')
			switch (this.computedPage.name) {
				case 'WeatherGraph':
					title[0] = '頭痛ーる 気圧グラフ'
					title[1] = this.cityName
					break
				case 'WeatherMap':
					title[0] = '全国マップ'
					title[1] = date
					break
			}
			return title
		},

		/**
		 * ページ（コンポーネント）切り替え処理
		 */
		computedPage () {
			// デフォルトはスゴ得TOP
			let page = 'sugotokuTop'
			const component = {
				name: '',
				props: null,
				key: ''
			}
			// query.urlのURLについている_pathパラメータをみてページを切り替える
			const query = this.$route.query
			let url = query.url
			if (url) {
				console.log('query.url: ', url)
				const arr = url.split('?_path=')
				url = arr[0]
				console.log('url: ', url)
				const path = arr[1]
				console.log('path: ', path)
				page = path
			}
			console.log('query', this.$route.query)
			console.log('params', this.$route.params)

			// NOTE: iOS Verチェック
			if (page !== 'nonMember' && this.isUnsupportedDevice) page = 'sorryPage'

			// AndroidのWEBアクセスを許可するページフラグ
			let isAndroidView = false

			// ページの出しわけ
			switch (page) {
				case 'sugotokuTop':
					component.name = 'SugotokuTop'
					component.props = {
						debugDate: query._debugDate ? query._debugDate : ''
					}
					break
				case 'weatherGraph':
					component.name = 'WeatherGraph'
					component.props = null
					break
				case 'weatherMap':
					component.name = 'WeatherMap'
					component.props = null
					break
				// NOTE: 現状スゴ得では使用していないが今後使用する可能性があるためコメントアウトで残しておく
				// case 'painNote':
				// 	component.name = 'PainNote'
				// 	component.props = null
				// 	break
				case 'pointRegistration':
					component.name = 'PointRegistration'
					component.props = null
					isAndroidView = true
					break
				case 'frequentlyAskedQuestions':
					component.name = 'FrequentlyAskedQuestions'
					component.props = null
					isAndroidView = this.isAndroid && this.isWebview
					break
				// NOTE: 現状スゴ得では使用していないが今後使用する可能性があるためコメントアウトで残しておく
				// case 'profile':
				// 	component.name = 'Profile'
				// 	component.props = null
				// 	break
				case 'pointTutorial':
					component.name = 'PointTutorial'
					component.props = null
					break
				case 'stamp':
					component.name = 'Stamp'
					component.props = {
						backPage: this.isAndroid && !this.isWebview ? 'top' : query._backPage === 'top' ? 'top' : 'weatherGraph',
						stampType: query._stampType ? query._stampType : '',
						debugDate: query._debugDate ? query._debugDate : ''
					}
					isAndroidView = true
					break
				case 'stampBloodType':
					component.name = 'StampBloodType'
					isAndroidView = true
					break
				case 'appDownload':
					component.name = 'AppDownload'
					component.props = {
						backPage: this.isAndroid && !this.isWebview ? 'top' : query._backPage === 'top' ? 'top' : 'weatherGraph',
						debugDate: query._debugDate ? query._debugDate : ''
					}
					isAndroidView = true
					break
				case 'info':
					component.name = 'Info'
					component.props = null
					isAndroidView = true
					break
				case 'special':
					component.name = 'Special'
					component.props = {
						specialType: query._specialType ? query._specialType : ''
					}
					isAndroidView = true
					break
				case 'lp':
					component.name = 'Lp'
					component.props = {
						lpType: query._lpType ? query._lpType : ''
					}
					isAndroidView = true
					break
				case 'terms':
					component.name = 'Terms'
					component.props = null
					isAndroidView = true
					break
				case 'nonMember':
					component.name = 'NonMember'
					component.props = null
					isAndroidView = true
					break
				case 'charactor':
					component.name = 'Charactor'
					component.props = null
					break
				case 'dataHandling':
					component.name = 'DataHandling'
					component.props = null
					isAndroidView = true
					break
				case 'columnTop':
					component.name = 'ColumnTop'
					component.props = {
						categoryId: isNaN(query._categoryId) ? null : Number(query._categoryId)
					}
					isAndroidView = true
					break
				case 'columnDetail':
					component.name = 'ColumnDetail'
					component.props = {
						detailId: isNaN(query._detailId) ? null : Number(query._detailId)
					}
					isAndroidView = true
					break
				case 'selfcheckMeteoropathy':
					component.name = 'SelfcheckMeteoropathy'
					component.props = null
					isAndroidView = true
					break
				case 'selfcheckMeteoropathyRes':
					component.name = 'SelfcheckMeteoropathyRes'
					component.props = {
						result: query._result ? query._result : '',
						answer: query._answer ? query._answer : ''
					}
					isAndroidView = true
					break
				case 'sixBadHabit':
					component.name = 'SixBadHabit'
					component.props = null
					isAndroidView = true
					break
				case 'game':
					component.name = 'Game'
					component.props = {
						gameType: query._gameType ? query._gameType : ''
					}
					isAndroidView = true
					break
				case 'balancecheckAutonomicNerves':
					component.name = 'BalancecheckAutonomicNerves'
					component.props = null
					isAndroidView = true
					break
				case 'balancecheckAutonomicNervesRes':
					component.name = 'BalancecheckAutonomicNervesRes'
					component.props = {
						result: query._result ? query._result : '',
						answer: query._answer ? query._answer : ''
					}
					isAndroidView = true
					break
				case 'selfcheckTemperatureDifference':
					component.name = 'SelfcheckTemperatureDifference'
					component.props = null
					isAndroidView = true
					break
				case 'selfcheckTemperatureDifferenceRes':
					component.name = 'SelfcheckTemperatureDifferenceRes'
					component.props = {
						result: query._result ? query._result : '',
						answer: query._answer ? query._answer : ''
					}
					isAndroidView = true
					break
				case 'stresscheckDiagnosis':
					component.name = 'StresscheckDiagnosis'
					component.props = null
					isAndroidView = true
					break
				case 'stresscheckDiagnosisRes':
					component.name = 'StresscheckDiagnosisRes'
					component.props = {
						result: query._result ? query._result : ''
					}
					isAndroidView = true
					break
				case 'headcheTypeContent':
					component.name = 'HeadcheTypeContent'
					component.props = null
					isAndroidView = true
					break
				case 'heatstrokeQuizProblem':
					component.name = 'HeatstrokeQuizProblem'
					component.props = null
					isAndroidView = true
					break
				case 'heatstrokeQuizAnswer':
					component.name = 'HeatstrokeQuizAnswer'
					component.props = null
					isAndroidView = true
					break
				case 'quizSpring':
					component.name = 'QuizSpring'
					component.props = null
					isAndroidView = true
					break
				case 'quizSpringRes':
					component.name = 'QuizSpringRes'
					component.props = {
						result: query._result ? query._result : ''
					}
					isAndroidView = true
					break
				case 'wallpaper':
					component.name = 'Wallpaper'
					component.props = null
					isAndroidView = true
					break
				case 'sendingPersonalData':
					component.name = 'SendingPersonalData'
					component.props = null
					isAndroidView = true
					break
				case 'error':
					component.name = 'SorryPage'
					component.props = {
						text: 'サーバーで問題が発生しているため、ページを表示できません。<br>しばらく時間を置いてからやり直してください。'
					}
					isAndroidView = true
					break
				case 'sorryPage':
					component.name = 'SorryPage'
					component.props = {
						text: '申し訳ございません。<br>お使いの端末ではこのサービスをご利用いただけません。'
					}
					isAndroidView = true
					break
				default:
					component.name = 'SorryPage'
					component.props = {
						text: '申し訳ございません。<br>お探しのページは見つかりません。'
					}
					isAndroidView = true
					break
			}

			// CHANGE: 同じコンポーネント間の遷移でも強制的に再マウントするためにkey属性をバインドするようにした（VueJsはkeyが変更されると再マウントする仕組みのようです）。
			// keyの生成
			const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randStr = ''
			for (let i = 0, keyLength = 8; i < keyLength; i++) {
				randStr += chars.charAt(Math.floor(Math.random() * chars.length))
			}
			component.key = `${component.name}_${randStr}`
			// console.log('component.key: ', component.key)

			// 未契約ページ、エラー、コラム詳細ページ、コラムTOP、利用規約、データの取り扱いページ、スタンプページはAndroidのWEBアクセスを許可する
			// NOTE: コラム、スタンプページはメッセージR誘導があるためAndroidでも受け付ける
			if (!isAndroidView && this.isAndroid && !this.isWebview) component.name = 'AppDownload'

			return component
		}
	},
	created () {
		// NOTE: iOS Verチェック
		// NOTE: User-Agent Client Hints対応で処理の見直し
		const minVersion = 11.3
		const userAgentCh = window.navigator.userAgentData
		if (!this.isAndroid) {
			if (userAgentCh) {
				const getHighEntropy = async () => {
					const useAgentData = userAgentCh
					const highEntropyValues = await useAgentData.getHighEntropyValues([
						'architecture',
						'bitness',
						'brands',
						'mobile',
						'model',
						'platform',
						'platformVersion',
						'uaFullVersion',
						'fullVersionList',
						'wow64'
					])
					// console.log('highEntropyValues: ', highEntropyValues)	// Debug用
					// NOTE: iOS版のChromeについては2022/12/27時点でUser-Agent Client Hintsに対応していない模様。いつ対応するのか分からないのでWicgのドキュメント（https://wicg.github.io/ua-client-hints/#sec-ch-ua-platform）に記載のplatform名を正として判定するようにした。
					// NOTE: バージョンはx.x.xのケースもあるが、parseFloatは2番目の小数点に遭遇した場合、解析を停止してその位置より前までの文字を解析するのでparseFloatで比較をするようにした
					this.isUnsupportedDevice = (userAgentCh.mobile && (highEntropyValues.platform.toLowerCase() === 'ios' || highEntropyValues.platform.toLowerCase() === 'macos') && parseFloat(highEntropyValues.platformVersion) < minVersion)
					console.log('isUnsupportedDevice: ', this.isUnsupportedDevice)
				}
				getHighEntropy()
			} else {
				const userAgent = window.navigator.userAgent.toLowerCase()
				const arrVersion = userAgent.split('os ')
				this.isUnsupportedDevice = (arrVersion.length >= 2 && arrVersion[1].split(' ')[0].replace('_', '.').replace(/_.*$/, '') < minVersion)
			}
		}
	},
	mounted () {
		const style = document.body.getAttribute('style')
		document.body.setAttribute('style', `${style} position: static; height: auto; overflow: auto;`)

		// NOTE: スゴ得用広告の処理
		// ユーザー情報をチェック
		// TODO: ユーザー情報の取得はここで1回で済ませたい（現状、各コンポーネントでも呼ばれている）。
		this.SET_USER_DATA({
			user_id: this.userId,
			user_token: this.userToken
		}).then(res => {
			console.log('userData: ', this.userData)
			if (this.userData) this.mopoRequest()
		}).catch(error => {
			console.log('Error SET_USER_DATA: ', error)
		})
	},
	beforeDestroy () {
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_MENU_OPEN, SET_USER_DATA]),

		/**
		 * コンテンツの高さを設定する関数
		 */
		setContainerHeight ($event) {
			// マージンを打ち消すクラスを消しておく
			// CHANGE: ドコモフッター変更により処理が不要になったのでコメントアウト
			// const domApp = document.getElementById('app')
			// domApp.classList.remove('is-negative-margin')

			this.$nextTick(() => {
				const height = !isNaN($event) ? `${$event + cmnConst.HEADER_HEIGHT}px` : $event
				const domApp = document.getElementById('app')
				domApp.setAttribute('style', `height: ${height}`)

				// NOTE: スゴ得フッターのマージンを打ち消す必要があるページはclass追加
				// NOTE: マイページ未登録時にネガティブマージンを取るとボタンが隠れてしまうためいったんマイページ未登録時はネガティブマージンを取らないようにした。問題あれば他の方法を検討。
				// CHANGE: ドコモフッター変更により処理が不要になったのでコメントアウト
				// if (this.isMyPage && (this.computedPage.name === 'PainNote' || this.computedPage.name === 'PointRegistration' || this.computedPage.name === 'FrequentlyAskedQuestions' || this.computedPage.name === 'Charactor' || this.computedPage.name === 'SorryPage')) domApp.classList.add('is-negative-margin')
			})
		},

		/**
		 * 広告タグの設定
		 */
		mopoRequest () {
			// セッションID取得
			const sessionId = this.$route.query.mopoa
			let sugotokuId = this.userData.sugotoku_id ? this.userData.sugotoku_id : null
			// console.log('sessionId: ', sessionId)
			// console.log('sugotokuId: ', sugotokuId)
			// console.log('this.computedPage.name: ', this.computedPage.name)

			// NOTE: sessionIdとsugotokuIdがある、且つページがSugotokuTopかAppDownload（Android）かLpページの場合にmopoリクエスト
			if (sessionId && sugotokuId && (this.computedPage.name === 'SugotokuTop' || (this.computedPage.name === 'AppDownload' && this.isAndroid) || this.computedPage.name === 'Lp')) {
				let shaObj = new JsSHA('SHA-256', 'TEXT')
				shaObj.update(sugotokuId)
				sugotokuId = shaObj.getHash('HEX')
				// console.log('sugotokuId(sha256): ', sugotokuId)

				axios.request({
					method: 'post',
					url: cmnConst.MOPO_API_URL,
					params: {
						t_id: sugotokuId,
						mopoa: sessionId
					}
				}).then(res => {
					if (res.data.result_code === '4001') {
						console.log('mopoRequest Error: ', res)
					} else {
						console.log('mopoRequest Success: ', res)
					}
				}).catch(error => {
					console.log('mopoRequest Error: ', error)
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.sugotoku-button {
	margin-top: $spacing-20;
}

</style>
